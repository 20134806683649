<style lang="scss" scoped>
	.item1 {
		position: relative;
		width: 100%;
		height: 100%;
		background: url(../../assets/images/live/item1_bg.jpg) no-repeat center center / cover;
		color: #FFF;
		text-align: center;

		h1 {
			padding-top: 150px;
			font-size: 40px;
			margin-bottom: 25px;
			font-weight: 700;
		}

		h2 {
			font-size: 28px;
			font-weight: 100;
			margin-bottom: 40px;
		}
		.item-black {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.button {
			display: flex;
			width: 240px;
			height: 48px;
			margin: 0 10px;
			justify-content: space-around;
			align-items: center;
			background: $--door-download-button-1;
			padding: 10px 20px;
			border-radius: 30px;
			cursor: pointer;

			.msg {
				color: #FFFFFF;
				text-align: left;

				.msg1 {
					font-size: 14px;
					margin-bottom: 5px;
				}
			}
		}

		.pic1 {
			position: absolute;
			top: 400px;
			left: 50%;
			margin-left: -500px;
			width: 1000px;
		}
	}

	.item2 {
		display: flex;
		width: 80%;
		min-width: 1200px;
		justify-content: space-between;
		margin: 0 auto;

		.pic {
			display: flex;
			align-items: center;

			img {
				width: 680px;
			}
		}

		.msg {
			width: 500px;
			display: flex;
			flex-flow: column;
			justify-content: center;

			h1 {
				font-size: 40px;
				font-weight: 700;
				margin-bottom: 20px;
			}

			h2 {
				color: #8B8B8B;
				font-size: 30px;
				font-weight: 100;
				margin-bottom: 10px;
				line-height: 150%;
			}
		}
	}

	.item3 {
		display: flex;
		margin: 0 40px;
		overflow: hidden;
		justify-content: space-between;

		h1 {
			font-size: 40px;
			font-weight: 700;
			text-align: center;
			margin-bottom: 20px;
			white-space: nowrap;
		}

		h2 {
			font-size: 17px;
			margin-bottom: 10px;
			color: #777;
			white-space: nowrap;
		}

		.exhibition {
			display: flex;
			flex-flow: column;
			align-items: center;
			box-shadow: 20px 0 30px -40px #000000;
			width: 70%;
			transition: all 1s;
		}

		.exhibition2 {
			display: flex;
			flex-flow: column;
			align-items: center;
			width: 30%;
			overflow: hidden;
			box-shadow: 20px 0 30px -40px #000000;
			transition: all 1s;
		}

		.pic {
			position: relative;
			height: 25.5vw;
			margin-top: 30px;

			img {
				height: 25.5vw;
			}

			.mark {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background: rgba(255, 255, 255, .6);
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				transition: all 2s;

				.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 120px;
					height: 120px;
					background: $--door-download-button-2;
					border-radius: 60px;
					font-size: 60px;
					color: #FFF;
					margin-bottom: 20px;
					font-weight: 700;
					cursor: pointer;
				}

				.test {
					font-size: 20px;
					cursor: pointer;
				}
			}

			.mark2 {
				display: none;
			}
		}
	}

	.item4 {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		.logo {
			width: 230px;
			margin-bottom: 60px;
		}
		.item-block {
			display: flex;
		}
		.button {
			display: flex;
			width: 240px;
			cursor: pointer;
			margin: 0 10px 30px;
			justify-content: space-around;
			align-items: center;
			padding: 13px 20px;
			border-radius: 10px;
			background: #5e5e5e;
			border: 1px solid #dcdcdc;

			.msg {
				text-align: left;

				.msg1 {
					color: #FFF;
					font-size: 14px;
					margin-bottom: 5px;
				}

				.msg2 {
					color: #8B8B8B;
				}
			}
		}

		.other {
			color: #FFF;
			font-size: 14px;
			line-height: 180%;
			text-align: center;
			margin-top: 200px;
			span {
				margin: 0 10px;
			}
			.beian-icon {
				width: 20px;
				height: 20px;
				display: inline-block;
				margin-right: 5px;
				vertical-align: middle;
				background-image: url('~@/assets/images/beian-i.png');
				background-size: 100% auto;
				background-repeat: no-repeat;
			}
		}
	}
	.codes {
		// width: 410px;
		background: #FFF;
		border-radius: 15px;
	
		.code {
			width: 200px;
			text-align: center;
	
			.img {
				width: 200px;
				height: 200px;
				background: rgba(0,0,0,.3);
			}
	
			h2 {
				color: #8B8B8B;
				font-size: 12px;
			}
		}
	
		.line {
			height: 180px;
			width: 1px;
			background: #8B8B8B;
			margin: 0 40px;
		}
	}
</style>
<template>
	<div>
		<topMenu v-bind:top="top" />
		<full-page :options="options" id="fullpage" ref="fullpage">
			<div class="section">
				<div class="item1">
					<h1>{{$t('live.upload.title')}}</h1>
					<h2>{{$t('live.upload.subtitle')}}</h2>
					<div class="item-black">
						<el-popover
							placement="bottom"
							width="200"
							trigger="hover">
							<div style="text-align: center;width: 200px;height: 200px;background-color: #DDDDDD;">
								<vue-qr :text="sdkInfo.download_url" v-if="sdkInfo.download_url"></vue-qr>
							</div>
							<div class="button" slot="reference">
								<img src="@/assets/images/live/icon2.png" alt="" class="icon1">
								<div class="msg">
									<div class="msg1">{{$t('live.upload.mobileTitle')}}</div>
									<div class="msg2">{{$t('live.upload.mobileSupport')}}</div>
								</div>
								<img src="@/assets/images/live/icon1.png" alt="" class="icon1">
							</div>
						</el-popover>
						<a class="button" :href="$aliyunclientSetup" @click="handleDown">
							<img src="@/assets/images/live/icon3.png" alt="" class="icon1">
							<div class="msg">
								<div class="msg1">{{$t('live.upload.pcTitle')}}</div>
								<div class="msg2">{{$t('live.upload.pcSupport')}}</div>
							</div>
							<img src="@/assets/images/live/icon4.png" alt="" class="icon1">
						</a>
					</div>
					<img src="@/assets/images/live/pic1.png" alt="" class="pic1">
				</div>
			</div>
			<div class="section">
				<div class="item2">
					<div class="msg">
						<h1>{{$t('live.upload.pageTitle1')}}</h1>
						<h2>{{$t('live.upload.pageText1-1')}}</h2>
						<h2>{{$t('live.upload.pageText1-2')}}</h2>
					</div>
					<div class="pic">
						<img src="@/assets/images/live/pic3.png" alt="" class="pic3">
					</div>
				</div>
			</div>
			<div class="section">
				<div class="item2">
					<div class="pic">
						<img src="@/assets/images/live/pic2.png" alt="" class="pic2">
					</div>
					<div class="msg">
						<h1>{{$t('live.upload.pageTitle2')}}</h1>
						<h2>{{$t('live.upload.pageText2-1')}}</h2>
						<h2>{{$t('live.upload.pageText2-2')}}</h2>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="item3">
					<div :class="left==1?'exhibition':'exhibition2'">
						<div>
							<h1>{{$t('live.upload.pageTitle3')}}</h1>
							<h2>{{$t('live.upload.pageText3-1')}}</h2>
							<h2>{{$t('live.upload.pageText3-2')}}</h2>
						</div>
						<div class="pic">
							<div :class="left==0?'mark':'mark2'">
								<div class="icon" @click="changeLeft">
									<i class="el-icon-arrow-right"></i>
								</div>
								<div class="test" @click="changeLeft">{{$t('live.upload.open')}}</div>
							</div>
							<img src="@/assets/images/live/pic4.png" alt="">
						</div>
					</div>
					<div :class="left==0?'exhibition':'exhibition2'">
						<div>
							<h1>{{$t('live.upload.pageTitle4')}}</h1>
							<h2>{{$t('live.upload.pageText4-1')}}</h2>
							<h2>{{$t('live.upload.pageText4-2')}}</h2>
						</div>
						<div class="pic">
							<div :class="left==1?'mark':'mark2'">
								<div class="icon" @click="changeLeft">
									<i class="el-icon-arrow-left"></i>
								</div>
								<div class="test" @click="changeLeft">{{$t('live.upload.open')}}</div>
							</div>
							<img src="@/assets/images/live/pic5.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="item4">
					<img src="@/assets/images/logo-292-80-white.png" alt="" class="logo" />
					<div class="item-block">
						<el-popover
							placement="bottom"
							width="200px"
							trigger="hover">
							<div class="codes">
								<!-- <div class="code">
									<div class="img"></div>
									<h2>{{$t('live.upload.scan')}}</h2>
									<h2>{{$t('live.upload.downIos')}}</h2>
								</div>
								<div class="line"></div> -->
								<div class="code">
									<div class="img"><vue-qr :text="sdkInfo.download_url" v-if="sdkInfo.download_url"></vue-qr></div>
									<h2>{{$t('live.upload.scan')}}</h2>
									<h2>{{$t('live.upload.downAndroid')}}</h2>
								</div>
							</div>
							<div class="button" slot="reference">
								<img src="@/assets/images/live/icon2.png" alt="" class="icon1">
								<div class="msg">
									<div class="msg1">{{$t('live.upload.mobileTitle')}}</div>
									<div class="msg2">{{$t('live.upload.mobileSupport')}}</div>
								</div>
								<img src="@/assets/images/live/icon1.png" alt="" class="icon1">
							</div>
						</el-popover>
						
						<a class="button" :href="$aliyunclientSetup" @click="handleDown">
							<img src="@/assets/images/live/icon3.png" alt="" class="icon1">
							<div class="msg">
								<div class="msg1">{{$t('live.upload.pcTitle')}}</div>
								<div class="msg2">{{$t('live.upload.pcSupport')}}</div>
							</div>
							<img src="@/assets/images/live/icon4.png" alt="" class="icon1">
						</a>
					</div>
					<div class="other">
						<div>{{$t('live.upload.copyright')}} </div>
						<!-- <div><span>{{$t('live.upload.ICPText1')}}</span><span>{{$t('live.upload.ICPText2')}}</span><span><i class="beian-icon"></i>{{$t('live.upload.ICPText3')}}</span></div> -->
					</div>
				</div>
			</div>
		</full-page>
	</div>
</template>
<script>
	import VueQr from 'vue-qr';
	import topMenu from "@/components/live/topMenu.vue";
	import { getApk, downloadScore } from "@/api/live";
	export default {
		components: {
			topMenu,
			VueQr
		},
		data() {
			return {
				options: {
					afterLoad: this.afterLoad,
					navigation: true,
					anchors: ['page1', 'page2', 'page3', 'page4', 'page5'],
					sectionsColor: ['#fff', '#fff', '#fff', '#fff', '#2b2b2b', ]
				},
				top: 0,
				left: 0,
				sdkInfo: {}
			};
		},
		created: function() {},
		mounted: function() {
			this.getSdkInfo();
		},
		methods: {
			getSdkInfo(){
				getApk().then(response => {
					this.sdkInfo = response.data || {};
				})
			},
			handleSelect(key, keyPath) {
				// console.log(key, keyPath);
			},
			afterLoad(origin, destination, direction) {
				this.top = destination.index
			},
			changeLeft() {
				if (this.left == 0) {
					this.left = 1
				} else {
					this.left = 0
				}
			},
			handleDown(){
				let param = {
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey,
				}
				downloadScore(param).then(() => {
					
				})
			}
		},
	};
</script>
