import request from '@/utils/request'
import { param2Obj } from '@/utils'

export function getApk(data) {
  return request({
    url: '/apk/new/detail',
    method: 'get',
    data: param2Obj(data)
  })
}

export function downloadScore(data) {
  return request({
    url: '/liveapi/score/record/pc/live/download',
    method: 'post',
    data: param2Obj(data)
  })
}
